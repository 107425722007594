<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Admin" />
		<div>
			<div v-if="!!loading">Loading......................</div>
			<div>
				<b-button class="is-success margin-top-20" @click="refresh">
					Refresh
				</b-button>
			</div>
			<div class="margin-top-20">
				<ve-table
					:columns="columns"
					:rows="rows"
					:searchedOptions="true"
					:sort-options="sortOptions"
					:onRowDoubleClick="onRowDoubleClick"
					:onCellClick="onCellClick"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</ve-table>
			</div>
			<div v-if="!!isPopupVisible">
				<modal-invoice-frame :data="invoiceData" :handler="refresh" />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import GetInvoicesToChangeInvoiceDueDateQuery from '@/_srcv2/pages/admin/super-privileges/change-invoice-due-date/GetInvoicesToChangeInvoiceDueDateQuery.graphql'
import { ref, reactive, onMounted } from '@vue/composition-api'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import ModalInvoiceFrame from '@/_srcv2/pages/admin/super-privileges/change-invoice-due-date/ModalInvoiceFrame.vue'
import useChangeInvoiceDueDateStore from '@/_srcv2/pages/admin/super-privileges/change-invoice-due-date/useChangeInvoiceDueDateStore.js'

export default {
	name: 'ChangeInvoiceDueDate',
	components: {
		SubNavbar,
		ModalInvoiceFrame,
	},
	setup() {
		// ---------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetInvoicesToChangeInvoiceDueDateQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// ----------------------------------------------------------------------------
		const rows = ref([])
		const columns = ref([])
		const mapData = (array) => {
			return array.map((item) => {
				console.log('array, array')
				return {
					invoiceDate: item.invoice_date,
					invoiceDueDate: item.invoice_due_date,
					invoiceNumber: item.invoice_number,
					invoiceType: item.invoice_type,
					customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
				}
			})
		}
		const setData = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const tableRows = mapData(result)
					resolve(tableRows)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const refresh = () => {
			refetch()
				.then((result) => setData(result.data.document_transactions))
				.then((tableRows) => (rows.value = tableRows))
				.then(() => console.log('rows.value', rows.value))
		}
		// ----------------------------------------------------------------------
		onMounted(() => {
			refresh()
		})
		// --------------------------------------------------------------------------
		columns.value = [
			{
				label: 'Select',
				field: 'select',
				width: '85px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '110px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '110px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '110px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '540px',
			},
		]
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNumber', type: 'desc' },
		}
		// -----------------------------------------------------------------------------------
		const invoiceData = reactive({
			invoiceNumber: '',
			customer: '',
			invoiceDate: '',
			dueDate: '',
		})
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'select'
			) {
				setVariables(params.row).then(() => {
					isPopupVisible.value = true
				})
			}
		}
		const onRowDoubleClick = (params) => {
			setVariables(params.row).then(() => {
				isPopupVisible.value = true
			})
		}
		// -------------------------------------------------------------------------------------------------
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('row', row)
					invoiceData.invoiceNumber = row.invoiceNumber
					invoiceData.customer = row.customer
					invoiceData.invoiceDate = row.invoiceDate
					invoiceData.dueDate = row.invoiceDueDate
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ** ---------------------------------------------------------------------------------------
		const { isPopupVisible } = useChangeInvoiceDueDateStore()
		return {
			loading,
			refresh,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			isPopupVisible,
			invoiceData,
		}
	},
}
</script>

<style scoped></style>
